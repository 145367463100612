<template>
  <el-select
    v-model="form.Value"
    :disabled="disabled"
    @change="changeInput"
    placeholder=""
    filterable
    class="country-code-select"
    style="width: 110px"
  >
    <el-option v-for="item in mobileCodeList" :key="item.code" :value="item.code">
      <div style="display: flex; align-items: center">
        <CountryFlag :country="item.country" size="small" />&nbsp;&nbsp;{{
          $t.translateCountry(item)
        }}
        {{ item.code }}
      </div>
    </el-option>
  </el-select>
</template>
<script>
import { reactive, ref, onMounted, watch } from "vue";
import CountryFlag from "vue-country-flag-next";
import { store } from "@/store";

export default {
  components: {
    CountryFlag,
  },
  props: {
    disabled: Boolean,
    modelValue: String,
  },
  emits: ["change", "update:modelValue"],
  setup(props, context) {
    let { mobileCodeList } = store.getters["config/getData"]?.envConf;

    let form = reactive({
      Value: props.modelValue,
    });

    watch(props, () => {
      if (props.modelValue) {
        form.Value = props.modelValue;
      } else {
        form.Value = "+852";
      }
    });

    onMounted(() => {
      setTimeout(() => {
        if (!props.modelValue) {
          form.Value = "+852";
          changeInput();
        }
      }, 300);
    });

    let changeInput = () => {
      context.emit("update:modelValue", form.Value);
      context.emit("change");
    };

    return { form, mobileCodeList, changeInput };
  },
};
</script>
